
.container {
  width: 100%;
  min-height: 100%;
  background-color: #212121;
  color: #ccc;
  display: flex;
  flex-direction: column;
}

.topBar {
  width: 100%;
  height: 60px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -webkit-touch-callout: none;
}
.topBar p {
  font-family: Favorit-Regular;
  font-size: 20px;
  padding-bottom: 3px;
  color: white;
}

.formContainer {
  padding: 15px 60px 75px 60px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.form {
  width: 385px;
  display: flex;
  flex-direction: column;
}

.form legend {
  padding: 0;
  font-family: Favorit-Regular;
  font-size: 32px;
  font-weight: 800;
}
.form label {
  display: block;
  margin-top: 20px;
  margin-bottom: 8px;
}
.form input {
  border-radius: 2px;
  height: 44px;
  padding: 13px 9px;
  font-size: 14px;
  background-color: #3f3f3f;
  border: 2px solid #6a6a6a;
  color: white;
  transition: border-color 0.2s ease;
  width: 100%;
}

.passwordField {
  position: relative;
}
.passwordField input {
  padding-right: 40px;
}
.passwordField__toggle {
  position: absolute;
  right: 10px;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form input:focus {
  outline: none;
  border-color: rgb(154, 154, 154);
}
.form button[type="submit"] {
  margin-top: 20px;
}

/* .form button[type="submit"]:disabled {
  opacity: 0.25;
  cursor: not-allowed;
} */

.error {
  display: flex;
  padding: 0.75rem;
  gap: 0.75rem;
  background-color: rgba(255, 0, 0, 0.2);
  border-radius: 2px;
  margin-top: 20px;
  border: 1px solid rgba(255, 0, 0, 0.5);
}
.error__icon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  color: #f00;
}
.error__text {
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;
}
.error__link {
  font-size: 14px;
  line-height: 20px;
  color: rgb(204, 204, 204);
  white-space: pre-line;
}

.forgot {
  margin-top: 20px;
  text-align: right;
}
.forgot a {
  color: #8a8a8a;
  font-size: 14px;
  line-height: 20px;
}
