.container {
  position: absolute;
  z-index: 10;
  top: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
}

.item {
  position: relative;
  background-color: white;
  color: black;
  padding: 15px;
  margin-bottom: 15px;
  min-height: 44px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.item--info {
  border-left: 5px solid #007aff;
}

.item--error {
  border-left: 5px solid red;
}

.item--marketing-suite.error {
  display: flex;
  flex-direction: row;
  width: 400px;
  padding: 15px 25px 15px 25px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.3));
  border-radius: 15px;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.3);
  margin-bottom: 15px;
}
.item--marketing-suite > .waiting {
  all: initial;
}
.item--marketing-suite.error > .icon {
  padding-right: 20px;
}

.item--marketing-suite > div > .title {
  font-weight: bold;
}
.item--marketing-suite > div > .text {
  padding-top: 2px;
}

.waiting-for-presenter {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #212121;
  border-radius: 50px;
  padding: 10px;
}
.waiting-for-presenter > svg {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
