.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eee;
}

.container__inner {
  color: black;
  margin: auto;
  max-width: 300px;
}

.input_label {
  display: block;
  margin-bottom: 5px;
}

.nickname,
.pin_input {
  display: block;
  height: 60px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  color: black;
  padding: 5px 15px;
  margin: 0 auto 15px auto;
  font-size: 32px;
}
.pin_input {
  margin-bottom: 35px;
}
.nickname:focus,
.pin_input:focus {
  background-color: white;
  outline: none;
}

.credit {
  margin-bottom: 15px;
  color: black;
  font-size: 14px;
  opacity: 0.5;
}
.credit a {
  color: black;
}
.credit a:hover {
  color: #26d07c;
}
